import { memo, useCallback } from 'react';
import { ButtonBase } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './circleRating.module.scss';
import ScaleContainer from './ScaleContainer';

const CircleRating = ({ value, onChange, disabled, minValue, maxValue, isDarkMode, labels }) => {

    const _onChange = useCallback(value => onChange({ value }), [onChange]);

    const length = (maxValue - minValue) + 1;

    return (
        <div className={cx(cn.container, isDarkMode && cn.dark)}>
            <ScaleContainer labelLow={labels.low} labelHigh={labels.high}>
                {Array.from({ length }).map((_, i) => (
                    <CircleItem key={i} value={i + minValue} active={i + minValue === value} onClick={_onChange} disabled={disabled} />
                ))}
            </ScaleContainer>
        </div>
    );

};

const CircleItem = memo(({ value, active, onClick, disabled }) => {

    const _onClick = useCallback(() => onClick(value), [onClick, value]);

    return (
        <ButtonBase
            onClick={_onClick}
            className={cx(cn.item, active && cn.active)}
            focusRipple
            disabled={disabled}
        >
            {value}
        </ButtonBase>
    );

});

export default memo(CircleRating);

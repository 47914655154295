import { memo, useCallback } from 'react';
import { TextField } from '@mui/material';

import { getLabelValue } from '../constants';
import QuestionTitle from '../components/QuestionTitle';

const Text = ({ content, required, value, locale, onChange, disabled }) => {

    const _onChange = useCallback(({ target: { value } }) => onChange({ value }), [onChange]);

    return (<>
        <QuestionTitle label={getLabelValue(content.title, locale)} required={required} />
        <TextField
            required={required}
            value={value ?? ''}
            onChange={_onChange}
            multiline
            rows={5}
            fullWidth
            disabled={disabled}
        />
    </>
    );

};

export default memo(Text);

import { forwardRef, memo } from 'react';
import { Button } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './buttons.module.scss';

export const SimpleButton = forwardRef(({ className, children, isDarkMode, ...props }, ref) => {

    return (
        <Button
            {...props}
            className={cx(cn.simpleButton, isDarkMode && 'dark', className)}
            ref={ref}
        >
            {children}
        </Button>
    );
});

export default memo(SimpleButton);

import { memo, useCallback, useState } from 'react';
import { Rating, Tooltip } from '@mui/material';

import { getLabelValue, NOT_CONCERNED_OPTION } from '../constants';

import cn from './starsRating.module.scss';
import { Option } from './Options';
import ScaleContainer from './ScaleContainer';

const StarsRating = ({ content, value, disabled, required, onChange, locale, i18n }) => {

    const isOnlyOneOption = !content?.options?.length;

    const _onChange = ({ id, value: _value }) => {
        if (isOnlyOneOption) return onChange({ value: _value });
        // multiple option
        const alreadyExists = (value ?? []).some(v => v.id === id);
        onChange({ value: alreadyExists ? value.map(v => v.id === id ? ({ ...v, value: _value }) : v) : [...(value ?? []), { id, value: _value }] });
    };

    const valueById = isOnlyOneOption ? {} : Object.fromEntries((value ?? []).map(v => [v.id, v.value]));

    return (
        <div className={cn.container}>
            {isOnlyOneOption && (
                <StarRating
                    disabled={disabled}
                    onChange={_onChange}
                    value={value}
                    optionAddNotConcerned={content.optionAddNotConcerned}
                    i18n={i18n.scales}
                    scale={content.scale}
                />)}
            {content?.options?.map(o => (
                <StarRating
                    key={o.id}
                    id={o.id}
                    disabled={disabled}
                    onChange={_onChange}
                    value={valueById[o.id]}
                    title={getLabelValue(o.title, locale)}
                    required={required}
                    optionAddNotConcerned={content.optionAddNotConcerned}
                    i18n={i18n.scales}
                    scale={content.scale}
                />
            ))}
        </div>
    );

};

const StarRating = ({ title, disabled, value, onChange, required, id, i18n, scale, optionAddNotConcerned }) => {

    return (
        <div className={cn.stars}>
            {title && <div className={cn.starTitle}>{title}{required && '*'}</div>}
            <ScaleContainer labelLow={i18n[scale]?.[1]} labelHigh={i18n[scale]?.[5]} className={cn.starsContainer}>
                <RatingWithTooltip
                    id={id}
                    value={value ?? 0}
                    onChange={onChange}
                    size="large"
                    required={required}
                    precision={1}
                    disabled={disabled}
                    i18n={i18n}
                    scale={scale}
                />
            </ScaleContainer>
            {optionAddNotConcerned && (
                <OptionNotConcerned
                    label={i18n.notConcerned}
                    checked={value === NOT_CONCERNED_OPTION}
                    onChange={onChange}
                    id={id}
                    disabled={disabled}
                />
            )}
        </div>
    );
};

const OptionNotConcerned = memo(({ disabled, onChange, checked, id, label }) => {

    const _onChange = useCallback(({ id, checked }) => {
        onChange({ value: checked ? NOT_CONCERNED_OPTION : '', id });
    }, [onChange]);

    return (
        <Option
            className={cn.itemNotConcerned}
            id={id}
            label={label}
            onSelect={_onChange}
            disabled={disabled}
            checked={checked}
        />
    );
});

const RatingWithTooltip = ({ disabled, value, required, onChange, scale, id, i18n }) => {

    const [tooltipLabel, setTooltipLabel] = useState('');

    const _onChange = useCallback((_, value) => onChange({ id, value }), [id, onChange]);

    const _onHover = useCallback((_, newValue) => {
        if (newValue === -1) return;
        setTooltipLabel(i18n?.[scale]?.[newValue] ?? '');
    }, [scale, i18n]);

    return (
        <Tooltip title={tooltipLabel} followCursor placement="top">
            <Rating
                value={value ?? 0}
                onChange={_onChange}
                size="large"
                required={required}
                precision={1}
                disabled={disabled}
                onChangeActive={_onHover}
            />
        </Tooltip>
    );

};

export default memo(StarsRating);

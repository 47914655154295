import { memo } from 'react';
import type { ButtonProps as MuiButonProps } from '@mui/material';
import { Button, LinearProgress } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './buttons.module.scss';

type Props = MuiButonProps & {
    label: string;
    loading: boolean;
}

const LoadingButton = ({ label, loading, disabled, className, ...props }: Props) => {

    return (
        <Button
            {...props}
            disabled={loading || disabled}
            className={cx(cn.loadingButton, className)}
            variant="contained"
            color="secondary"
            disableElevation
        >
            {label}
            {loading && <LinearProgress color="secondary" className={cn.progress} />}
        </Button>
    );
};

export default memo(LoadingButton);

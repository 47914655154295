import { memo } from 'react';
import { cx } from '@emotion/css';

import cn from './questionTitle.module.scss';

const QuestionTitle = ({ required, label, error }) => {

    return (
        <div className={cx(cn.title, required && cn.required, error && cn.error)}>
            {label}
        </div>
    );

};

export default memo(QuestionTitle);

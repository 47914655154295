export const TYPES = {
    CSAT: 'csat',
    NPS: 'nps',
    //PODIUM: 'user',
    RATING: 'rating',
    TEXT: 'text',
    MULTIPLE_CHOICES: 'multiple_choices',
    SINGLE_CHOICE: 'single_choice'
};

const QUESTION_RANGES = {
    [TYPES.CSAT]: {
        MIN: 1,
        MAX: 5
    },
    [TYPES.NPS]: {
        MIN: 0,
        MAX: 10
    },
    [TYPES.RATING]: {
        MIN: 1,
        MAX: 5
    }
};

export const getQuestionTypeRange = type => QUESTION_RANGES[type];

const CHECKS = {
    [TYPES.TEXT]: ({ value }) => (value ?? '').trim() !== '',
    [TYPES.MULTIPLE_CHOICES]: ({ value }) => (value ?? []).length >= 1,
    [TYPES.RATING]: ({ value, content }) => {
        if (!content.options || content.options.length === 0) return true;
        return value.length === content.options.length;
    }
};

export const getQuestionTypeChecker = type => CHECKS[type];

export const COMMENT_OPTIONS = {
    NONE: 'none',
    OPTIONAL: 'optional',
    REQUIRED: 'required',
    CONDITIONAL: 'conditional'
};

export const SCALE_OPTIONS = {
    AGREE: 'agree',
    SATISFACTION: 'satisfaction'
};

export const getLabelValue = (value, locale) => {
    if (typeof value === 'string') return value;
    const d = value?.fr ?? value?.en ?? '';
    if (locale) return value[locale] ?? d;
    return d;
};

export const NOT_CONCERNED_OPTION = 'not_concerned';

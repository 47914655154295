import { memo } from 'react';

import { getLabelValue } from '../constants';

import Comment from '../components/Comment';
import QuestionTitle from '../components/QuestionTitle';
import Options from '../components/Options';

const MultipleChoices = ({ i18n, ...data }) => {

    return (
        <>
            <QuestionTitle label={getLabelValue(data.content.title, data.locale)} required={data.required} />
            <Options {...data} isMultiple />
            <Comment
                i18n={i18n}
                {...data}
            />
        </>
    );
};

export default memo(MultipleChoices);

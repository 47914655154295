import { forwardRef, memo, useCallback } from 'react';
import { TextField as MuiTextField } from '@mui/material';

const TextField = forwardRef(({ id, onChange, onEnterPress, onKeyPress, returnValueOnly, ...props }, ref) => {

    const _onChange = useCallback(({ target: { value } }) => onChange(returnValueOnly ? value : { id, value }), [id, onChange, returnValueOnly]);
    const _onKeyPress = useCallback(({ key }) => {
        if (key === 'Enter' && onEnterPress) {
            onEnterPress();
        }
        onKeyPress?.(key);
    }, [onEnterPress, onKeyPress]);

    return <MuiTextField
        inputRef={ref}
        {...props}
        onChange={_onChange}
        onKeyUp={(onEnterPress || onKeyPress) ? _onKeyPress : null}
    />;

});

export default memo(TextField);

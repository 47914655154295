import { memo } from 'react';
import { Button } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './buttons.module.scss';

const CancelButton = ({label, className, children, ...props}) => {
    return (
        <Button {...props} className={cx(cn.buttonBase, className)}>
            {label || children}
        </Button>
    );
};

export default memo(CancelButton);

import { memo, useCallback, useEffect, useRef, useState } from 'react';
import _isNil from 'lodash.isnil';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { cx } from '@emotion/css';

import ImgSrcSurveyCompleted from './assets/survey_filled.svg';
import ImgSrcSurveyToStart from './assets/survey_to_start.svg';
import ImgSrcRemindSurvey from './assets/survey.svg';

import cn from './surveyContent.module.scss';

import { COMMENT_OPTIONS, getLabelValue, getQuestionTypeChecker, TYPES } from './constants';

import SimpleMd from '../SimpleMd';
import Confetti from '../Confetti';
import CancelButton from '../Buttons/CancelButton';
import StyledButton from '../Buttons/StyledButton';
import StyledLoadingButton from '../Buttons/StyledLoadingButton';

import Csat from './types/Csat';
import Nps from './types/Nps';
import Text from './types/Text';
import MultipleChoices from './types/MultipleChoices';
import SingleChoice from './types/SingleChoice';
import Ratings from './types/Ratings';

const SurveyContent = ({ i18n, ...props }) => {

    const [step, setStep] = useState(props.asAdmin ? 'survey' : 'introduction');

    const _onClickStart = useCallback(() => { setStep('survey'); }, []);
    const _onClickCancel = useCallback(() => setStep('remind'), []);
    const _onSurveySubmitted = useCallback(() => setStep('congratz'), []);

    if (step === 'introduction') { return <Introduction {...props} onStart={_onClickStart} onCancel={_onClickCancel} i18n={i18n.introduction} />; }
    if (step === 'survey') return <Survey {...props} onCancel={_onClickCancel} onSurveySubmitted={_onSurveySubmitted} i18n={i18n.content} />;
    if (step === 'remind') return <RemindLater i18n={i18n.remind} {...props} />;
    if (step === 'congratz') return <Congratz i18n={i18n.congrats} {...props} />;

    return null;
};

const Introduction = memo(({ onCancel, onStart, i18n, firstName }) => {

    return (<>
        <DialogContent>
            <div className={cn.title}>
                {i18n.title.replace('{{firstName}}', firstName)}
            </div>
            <SimpleMd value={i18n.body} />
            <div className={cn.illustration}>
                <ImgSrcSurveyToStart alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.cancel} onClick={onCancel} />
            <StyledButton label={i18n.go} onClick={onStart} />
        </DialogActions>
    </>);
});

const RemindLater = memo(({ onDiscardSurvey, survey, i18n, endDate }) => {

    const _onClick = useCallback(() => onDiscardSurvey(survey.id), [onDiscardSurvey, survey]);

    return (<>
        <DialogContent>
            <SimpleMd className={cn.remindContent} value={i18n.body.replace('{{date}}', endDate)} />
            <div className={cn.illustration}>
                <ImgSrcRemindSurvey alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.close} onClick={_onClick} />
        </DialogActions>
    </>);
});

const Congratz = memo(({ onDiscardSurvey, survey, i18n, firstName }) => {

    const _onClose = useCallback(() => onDiscardSurvey(survey.id), [onDiscardSurvey, survey.id]);

    return (<>
        <DialogContent>
            <div className={cn.title}>
                {i18n.title.replace('{{firstName}}', firstName)}
            </div>
            <SimpleMd value={i18n.body} />
            <div className={cn.illustration}>
                <ImgSrcSurveyCompleted alt="" />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.close} onClick={_onClose} />
        </DialogActions>
        <Confetti />
    </>);
});

const Survey = memo(({ survey, started, onAnswerQuestion, onSubmitSurvey, onSurveySubmitted, asAdmin, onCancel, onClose, i18n, locale }) => {

    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const _onClickCancel = useCallback(() => {
        if (asAdmin) {
            onClose();
            return;
        }
        if (!started || window.confirm(i18n.confirmCancel)) onCancel();
    }, [asAdmin, started, i18n.confirmCancel, onCancel, onClose]);

    const _onChangeSection = useCallback(sectionIndex => setActiveSectionIndex(sectionIndex), []);
    // This function do not need to be wrapped in useCallback as it would rely on "survey" in its dependency array, and survey changes everytime
    const _onCompleteSurvey = async () => {
        setLoading(true);
        await onSubmitSurvey(survey);
        setLoading(false);
        onSurveySubmitted();
    };

    const ref = useRef();

    useEffect(() => {
        if (!ref.current) return;
        setTimeout(() => {
            ref.current.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 50);
    }, [activeSectionIndex]);

    const _onMoveToNextSection = () => { setActiveSectionIndex(index => index + 1); };

    const activeSection = survey.sections[activeSectionIndex];
    const isFinalSection = activeSectionIndex === survey.sections.length - 1;

    // We can go next if every mandatory questions are filled (or we are admin, we can always go next)
    const canGoNext = asAdmin || activeSection.questions.filter(q => q.required).every(q => {
        if (q.content.additionalCommentType === COMMENT_OPTIONS.REQUIRED && (q.comment ?? '').trim() === '') return false;
        if (_isNil(q.value)) return false;
        const checker = getQuestionTypeChecker(q.type);
        return checker?.(q) ?? true;
    });

    return (<>
        <DialogTitle className={cn.breadcrumb}>
            <Breadcrumb current={activeSectionIndex} total={survey.sections.length} asAdmin={asAdmin} onClick={_onChangeSection} />
        </DialogTitle>
        <DialogContent className={cn.surveyContainer} ref={ref}>
            <Section content={activeSection} onAnswerQuestion={onAnswerQuestion} disabled={loading || asAdmin} i18n={i18n} locale={locale} />
        </DialogContent>
        <DialogActions>
            <CancelButton label={i18n.cancel} onClick={_onClickCancel} disabled={loading} />
            <StyledLoadingButton
                label={isFinalSection ? i18n.submit : i18n.next}
                onClick={isFinalSection ? _onCompleteSurvey : _onMoveToNextSection}
                disabled={!canGoNext || (isFinalSection && asAdmin)}
                loading={loading}
            />
        </DialogActions>
    </>);

});

const Breadcrumb = memo(({ onClick, current, total, asAdmin }) => {

    // No need to display a breadcrumb with only one section
    if (total <= 1) return null;

    return (
        <div className={cn.breadContainer}>
            {
                Array.from({ length: total }).map((_, i) => {
                    const isClickable = (asAdmin || (i < current)) && current !== i;

                    return (
                        <span
                            key={i}
                            className={cx(
                                cn.breadSection,
                                isClickable && 'clickable',
                                i < current && 'past',
                                i === current && 'current'
                            )}
                            onClick={() => isClickable && onClick(i)}
                        />
                    );
                })
            }
        </div>
    );

});

const Section = memo(({ content, onAnswerQuestion, disabled, locale, ...props }) => {

    const description = getLabelValue(content.description, locale);

    return (<>
        <div className={cx(cn.sectionContainer, cn.sectionTitleContainer)}>
            <div className={cn.sectionTitle}>
                {getLabelValue(content.title, locale)}
            </div>
            {description && (
                <div className={cn.sectionDescription}>
                    {description}
                </div>
            )}
        </div>
        <div className={cn.sectionContainer}>
            {content.questions.map(q => (
                <div className={cn.questionContainer} key={q.id}>
                    <QuestionDispatcher
                        {...q}
                        onAnswerQuestion={onAnswerQuestion}
                        disabled={disabled}
                        locale={locale}
                        {...props}
                    />
                </div>
            ))}
        </div>
    </>);

});

const QuestionDispatcher = memo(({ id, type, onAnswerQuestion, ...data }) => {

    const _onChange = useCallback(({ value, comment }) => onAnswerQuestion?.({ id, value, comment }), [onAnswerQuestion, id]);

    if (type === TYPES.RATING)
        return <Ratings {...data} onChange={_onChange} />;

    if (type === TYPES.CSAT)
        return <Csat {...data} onChange={_onChange} />;

    if (type === TYPES.NPS)
        return <Nps {...data} onChange={_onChange} />;

    if (type === TYPES.SINGLE_CHOICE)
        return <SingleChoice {...data} onChange={_onChange} />;

    if (type === TYPES.MULTIPLE_CHOICES)
        return <MultipleChoices {...data} onChange={_onChange} />;

    // Text
    return <Text {...data} onChange={_onChange} />;

});

export default memo(SurveyContent);


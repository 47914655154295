import { memo, forwardRef } from 'react';
import { Button, CircularProgress, LinearProgress } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './buttons.module.scss';

export const StyledLoadingButton = forwardRef(({
    label,
    labelLoading,
    loading,
    disabled,
    className,
    children,
    icon,
    color,
    ...props
}, ref) => {

    const displayLabel = (() => {
        if (loading && icon) return null;
        if (loading && labelLoading) return labelLoading;
        return children || label;
    })();

    return (
        <Button
            variant="contained"
            disableElevation
            ref={ref}
            disabled={loading || disabled}
            color={color ?? 'secondary'}
            className={cx(cn.button, className)}
            {...props}
        >
            {displayLabel}
            {loading && !icon && <LinearProgress color="secondary" className={cn.progress} />}
            {loading && icon && <span className={cn.progressCircular}><CircularProgress size={20} color="secondary" /></span>}
        </Button>
    );
});

export default memo(StyledLoadingButton);

import { memo, useCallback, useEffect, useState } from 'react';
import { Collapse, TextField } from '@mui/material';

import { COMMENT_OPTIONS, getLabelValue } from '../constants';

import QuestionTitle from './QuestionTitle';

import cn from './comment.module.scss';

const Comment = memo(({ comment, value, required: _required, content, i18n, onChange, disabled, locale }) => {

    const _onChange = useCallback(({ target: { value } }) => onChange({ comment: value }), [onChange]);

    const required = (content.additionalCommentType === COMMENT_OPTIONS.REQUIRED) && _required;

    const [showForm, setShowForm] = useState(content.additionalCommentType === COMMENT_OPTIONS.REQUIRED || content.additionalCommentType === COMMENT_OPTIONS.OPTIONAL);
    const [formLabel, setFormLabel] = useState('');

    useEffect(() => {
        if (content.additionalCommentType !== COMMENT_OPTIONS.CONDITIONAL) return;

        const hideForm = () => {
            setShowForm(false);
            setFormLabel('');
            onChange({ comment: '' });
        };

        if (!value) return hideForm();

        const optionByValue = content.options.find(o => o.id === value);
        if (!optionByValue) return hideForm();

        if (!optionByValue.comment) return hideForm();

        const label = getLabelValue(optionByValue.comment, locale);

        if (!label) return hideForm();

        setShowForm(true);
        setFormLabel(getLabelValue(optionByValue.comment, locale));

    }, [value, content, onChange, locale]);

    return (
        <Collapse in={showForm}>
            {formLabel && <div className={cn.commentLabel}>
                <QuestionTitle label={formLabel} />
            </div>}
            <TextField
                label={i18n.comment}
                required={required}
                value={comment ?? ''}
                onChange={_onChange}
                margin="normal"
                multiline
                rows={3}
                fullWidth
                disabled={disabled}
            />
        </Collapse>
    );

});

const CommentWrapper = ({ content, ...data }) => {

    if (content.additionalCommentType === COMMENT_OPTIONS.NONE) return null;

    return <Comment {...data} content={content} />;

};

export default memo(CommentWrapper);

import { memo } from 'react';

import { getLabelValue, getQuestionTypeRange, TYPES } from '../constants';

import CircleRating from '../components/CircleRating';
import Comment from '../components/Comment';
import QuestionTitle from '../components/QuestionTitle';

const Csat = ({ onChange, i18n, ...data }) => {

    const typeData = getQuestionTypeRange(TYPES.CSAT);

    return (
        <>
            <QuestionTitle label={getLabelValue(data.content.title, data.locale)} required={data.required} />
            <CircleRating
                {...data}
                onChange={onChange}
                minValue={typeData.MIN}
                maxValue={typeData.MAX}
                labels={i18n.labels.csat}
            />
            <Comment
                i18n={i18n}
                onChange={onChange}
                {...data}
            />
        </>
    );
};

export default memo(Csat);

import { memo } from 'react';
import { cx } from '@emotion/css';

import cn from './scaleContainer.module.scss';

const ScaleContainer = ({ className, labelLow, labelHigh, children }) => (
    <div className={cx(className, cn.container)}>
        <span className={cx(cn.label, cn.labelLow)}>{labelLow}</span>
        {children}
        <span className={cn.label}>{labelHigh}</span>
    </div>
);

export default memo(ScaleContainer);

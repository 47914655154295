import { memo, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import { cx } from '@emotion/css';

import { getLabelValue } from '../constants';

import cn from './options.module.scss';

const Options = ({ content, value, onChange, isMultiple, locale, disabled }) => {

    const _onSelectOption = useCallback(({ id, checked }) => {

        if (isMultiple) return onChange({ value: checked ? [...(value ?? []), id] : value.filter(v => v !== id) });
        onChange({ value: checked ? id : null });

    }, [isMultiple, onChange, value]);

    const isChecked = useCallback(id => {
        if (isMultiple) return (value ?? []).some(v => v === id);
        return value === id;
    }, [isMultiple, value]);

    return (
        <div className={cn.container}>
            {(content.options ?? []).map(o => (
                <Option disabled={disabled} key={o.id} id={o.id} onSelect={_onSelectOption} checked={isChecked(o.id)} label={getLabelValue(o.title, locale)} isMultiple={isMultiple} />
            ))}
        </div>
    );

};

const labelStyle = { typography: { minWidth: 0 } };
export const Option = memo(({ className, id, label, checked, onSelect, isMultiple, disabled }) => {

    const _onChange = useCallback(() => {
        onSelect({ id, checked: !checked });
    }, [id, checked, onSelect]);

    const control = useMemo(() => {
        if (isMultiple) return <Checkbox className={cn.radioOrCb} checked={checked} />;
        return <Radio className={cn.radioOrCb} checked={checked} />;
    }, [checked, isMultiple]);

    return (
        <FormControlLabel
            componentsProps={labelStyle}
            className={cx(className, cn.item, checked && cn.checked)}
            control={control}
            label={label}
            onChange={_onChange}
            disabled={disabled}
        />
    );
});

export default memo(Options);

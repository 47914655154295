
import { memo } from 'react';

import Comment from '../components/Comment';
import QuestionTitle from '../components/QuestionTitle';
import StarsRating from '../components/StarsRating';

import { getLabelValue } from '../constants';

const Ratings = data => {

    const isOnlyOneOption = !data?.content?.options?.length;

    return (
        <>
            <QuestionTitle label={getLabelValue(data.content.title, data.locale)} required={isOnlyOneOption && data.required} />
            <StarsRating {...data} />
            <Comment {...data} />
        </>
    );
};

export default memo(Ratings);

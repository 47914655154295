import { memo } from 'react';
import { Portal } from '@mui/material';
import Confetti from 'react-confetti';

import { useCss } from '../theme';

const ConfettiWorks = ({ numberOfPieces = 200, width, height }) => {

    const css = useCss();

    const cssConfetti = css(() => ({
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5000,
        pointerEvents: 'none',
    }));

    return (
        <Portal>
            <div className={cssConfetti}>
                <Confetti width={width} height={height} numberOfPieces={numberOfPieces} />
            </div>
        </Portal>
    );

};

export default memo(ConfettiWorks);

import { memo, forwardRef } from 'react';
import { Button } from '@mui/material';
import { cx } from '@emotion/css';

import cn from './buttons.module.scss';

export const StyledButton = forwardRef(({ className, label, children, color, ...props }, ref) => {

    return (
        <Button
            ref={ref}
            variant="contained"
            disableElevation
            color={color ?? 'secondary'}
            {...props}
            className={cx(cn.button, className)}
        >
            {label || children}
        </Button>
    );

});

export default memo(StyledButton);
